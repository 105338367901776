import DashboardTitleComponent from "../../custom/DashboardTitleComponent";
import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Menu, MenuItem
} from "@mui/material";
import CustomAutocompleteField from "../../custom/CustomAutoCompleteField";
import CustomIconButton from "../../custom/CustomIconButton";
import CustomTextField from "../../custom/CustomTextField";
import CustomButton from "../../custom/CustomButton";
import { isBlank } from "../../../utils/ValidationRules";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useUser } from "../../../pages/UserContext";
import { numberToRupiah } from "../../../utils/format";
import formatDateTimestamp from "../../../utils/date";
import Swal from "sweetalert2";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";

const AjuanPembayaranMenu = () => {
  const userDetails = useUser();

  // Form state
  const [listPembayaran, setListPembayaran] = useState("");
  const [jenisIuran, setJenisIuran] = useState("");
  const [biaya, setBiaya] = useState("");
  const [listPembayaranItems, setListPembayaranItems] = useState([]);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  const [open, setOpen] = useState(false);
  const [openAddMaster, setAddOpenMaster] = useState(false);

  useEffect(() => {
    const jwtToken = localStorage.getItem("token");
    const fetchData = async () => {
      try {
        let url = `${process.env.REACT_APP_BACKEND_HOST}/pembayaran-iuran`;
        if (userDetails.role !== "admin") {
          url += `?userId=${userDetails.uuid}`;
        }
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });

        const statusMapping = {
          pending: "Perlu Diproses",
          approved: "Diterima ✅",
          rejected: "Ditolak ❌",
        };

        setRows(
          response.data.data.map((iuranPembayaran, index) => ({
            no: index + 1 + (page - 1) * 10,
            id: iuranPembayaran.id,
            requestId: iuranPembayaran.request_id,
            jenisIuran: iuranPembayaran.nama_iuran,
            biaya: numberToRupiah(iuranPembayaran.biaya),
            keterangan: iuranPembayaran.keterangan,
            buktiFoto: iuranPembayaran.bukti_foto,
            namaLengkap:
              iuranPembayaran.gelar_depan +
              " " +
              iuranPembayaran.nama_lengkap +
              " " +
              iuranPembayaran.gelar_belakang,
            requestedAt: formatDateTimestamp(iuranPembayaran.requested_at),
            status:
              statusMapping[iuranPembayaran.status] || iuranPembayaran.status,
            statusRaw: iuranPembayaran.status,
          }))
        );
      } catch (error) {
        console.error("Error fetching instruct", error);
      }
    };
    fetchData();
  }, [page, refresh, userDetails.role, userDetails.uuid]);

  // useEffect(() => {
  //   const jwtToken = localStorage.getItem("token");
  //   if (jwtToken) {
  //     const fetchData = async () => {
  //       try {
  //         const response = await axios.get(
  //           `${process.env.REACT_APP_BACKEND_HOST}/master-pembayaran-iuran`,
  //           {
  //             headers: {
  //               Authorization: `Bearer ${jwtToken}`,
  //             },
  //           }
  //         );
  //         setListPembayaranItems(
  //           response.data.data.map((item) => ({
  //             uuid: item.uuid,
  //             name: item.name,
  //             biaya: item.biaya,
  //           }))
  //         );
  //       } catch (error) {
  //         alert("Error fetching payment options", error);
  //       }
  //     };
  //     fetchData();
  //   }
  // }, []);

  const handleFetchPembayaran = async () => {
    const jwtToken = localStorage.getItem("token");
    if (jwtToken) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_HOST}/master-pembayaran-iuran`,
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
        setListPembayaranItems(
          response.data.data.map((item) => ({
            uuid: item.uuid,
            name: item.name,
            biaya: item.biaya,
          }))
        );
      } catch (error) {
        alert("Error fetching payment options", error);
      }
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOpenMasterModal = () => {
    setAddOpenMaster(true);
  };

  const handleCloseMasterModal = () => {
    setAddOpenMaster(false);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setListPembayaran("");
    setSelectedFile(null);
    setPreviewImage(null);
  };

  const handleShowBuktiFotoPembayaran = (buktiFoto) => {
    const buktiFotoURL = `${process.env.REACT_APP_BACKEND_HOST}${buktiFoto}`;
    Swal.fire({
      imageUrl: buktiFotoURL,
      imageAlt: "Bukti pembayaran",
      showConfirmButton: false,
    });
  };

  const handleAddMasterIuran = async () => {
    if (!jenisIuran) {
      return Swal.fire({
        title: "Error!",
        text: "Harap isi jenis iuran yang ingin dibuat",
        icon: "error",
        didOpen: () => {
          document.querySelector(".swal2-container").style.zIndex = "9999";
          document.querySelector(".swal2-popup").style.zIndex = "99999";
        },
      });
    }

    if (!biaya) {
      return Swal.fire({
        title: "Error!",
        text: "Harap isi biaya iuran yang ingin dibuat",
        icon: "error",
        didOpen: () => {
          document.querySelector(".swal2-container").style.zIndex = "9999";
          document.querySelector(".swal2-popup").style.zIndex = "99999";
        },
      });
    }
  
    const requestData = {
      jenisIuran: jenisIuran,
      biaya: biaya,
    };
  
    const jwtToken = localStorage.getItem("token");
  
    try {
      setLoading(true);
  
      await axios.post(
        `${process.env.REACT_APP_BACKEND_HOST}/master-pembayaran-iuran`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      Swal.fire("Success!", "Request Pembayaran Berhasil Diajukan", "success");
  
      setJenisIuran(null);
      setBiaya(null);
      setAddOpenMaster(false);
      setRefresh((prev) => !prev);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Terjadi kesalahan saat mengajukan pembayaran.",
        icon: "error",
        didOpen: () => {
          document.querySelector(".swal2-container").style.zIndex = "9999";
          document.querySelector(".swal2-popup").style.zIndex = "99999";
        },
      });
    } finally {
      setLoading(false);
    }
  }

  const handleRequestPembayaranIuran = async () => {
    if (!listPembayaran) {
      return Swal.fire({
        title: "Error!",
        text: "Pilih jenis pembayaran terlebih dahulu!",
        icon: "error",
        didOpen: () => {
          document.querySelector(".swal2-container").style.zIndex = "9999";
          document.querySelector(".swal2-popup").style.zIndex = "99999";
        },
      });
    }

    if (!selectedFile) {
      return Swal.fire({
        title: "Error!",
        text: "Harap upload bukti pembayaran!",
        icon: "error",
        didOpen: () => {
          document.querySelector(".swal2-container").style.zIndex = "9999";
          document.querySelector(".swal2-popup").style.zIndex = "99999";
        },
      });
    }

    const formData = new FormData();
    formData.append("jenis_iuran", listPembayaran);
    formData.append("bukti_pembayaran", selectedFile);

    const jwtToken = localStorage.getItem("token");

    try {
      setLoading(true);
      Swal.fire({
        title: "Mohon Tunggu...",
        text: "Sedang memproses pembayaran",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      await axios.post(
        `${process.env.REACT_APP_BACKEND_HOST}/pembayaran-iuran/request`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      Swal.fire("Success!", "Request Pembayaran Berhasil Diajukan", "success");

      setListPembayaran(null);
      setSelectedFile(null);
      setOpen(false);
      setRefresh((prev) => !prev);
    } catch (error) {
      Swal.fire(
        "Error!",
        "Terjadi kesalahan saat mengajukan pembayaran.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleApprove = async (id, nama, jenisIuran) => {
    const jwtToken = localStorage.getItem("token");

    const result = await Swal.fire({
      title: "Konfirmasi Persetujuan",
      html: `Apakah Anda yakin ingin menyetujui pembayaran iuran 
        <span style="color: red;">[${nama}]</span> untuk jenis iuran <br>
        <span style="color: red;">[${jenisIuran}]</span>?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#003158",
      cancelButtonColor: "#A92027",
      confirmButtonText: "Terima",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_BACKEND_HOST}/pembayaran-iuran/request/decision`,
          {
            requestId: id,
            status: "approved",
            keterangan: "Disetujui Admin",
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
        if (response.status === 201) {
          Swal.fire({
            title: "Berhasil!",
            text: response.data.msg,
            icon: "success",
          });
          setRefresh((prev) => !prev);
        } else {
          Swal.fire({
            title: "Gagal!",
            text: response.data.msg,
            icon: "error",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Terjadi Kesalahan!",
          text: "Kesalahan saat menyetujui pembayaran.",
          icon: "error",
        });
      }
    }
  };

  const handleReject = async (id, nama, jenisIuran) => {
    const jwtToken = localStorage.getItem("token");

    const { value: keterangan } = await Swal.fire({
      title: "Konfirmasi Penolakan",
      html: `Anda akan melakukan penolakan pembayaran <br>
      <span style="color: red;">[${nama}]</span> untuk jenis iuran
       <span style="color: red;">[${jenisIuran}]</span>.<br><br>Tolong masukkan alasan penolakan pembayaran ini:`,
      input: "text",
      inputPlaceholder: "Contoh: Bukti pembayaran tidak valid",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#003158",
      cancelButtonColor: "#A92027",
      confirmButtonText: "Tolak",
      cancelButtonText: "Batal",
      inputValidator: (value) => {
        if (!value) {
          return "Anda harus mengisi alasan penolakan!";
        }
      },
    });

    if (keterangan) {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_BACKEND_HOST}/pembayaran-iuran/request/decision`,
          {
            requestId: id,
            status: "rejected",
            keterangan: keterangan,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );

        if (response.status === 201) {
          Swal.fire({
            title: "Berhasil!",
            text: response.data.msg,
            icon: "success",
          });
          setRefresh((prev) => !prev);
        } else {
          Swal.fire({
            title: "Gagal!",
            text: response.data.msg,
            icon: "error",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Terjadi Kesalahan!",
          text: "Kesalahan saat menolak pembayaran.",
          icon: "error",
        });
      }
    }
  };

  const AdminActionsDropdown = ({ params }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
  
    const handleMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
    };
  
    const isApproved = params.row.statusRaw === "approved";
    const isRejected = params.row.statusRaw === "rejected";
  
    return (
      <>
        <Button
          aria-controls="admin-menu"
          aria-haspopup="true"
          onClick={handleMenuOpen}
        >
          <MoreVertIcon />
        </Button>
        <Menu
          id="admin-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
        >
          {!isApproved && (
            <MenuItem
              onClick={() => {
                handleApprove(params.row.requestId, params.row.namaLengkap, params.row.jenisIuran);
                handleMenuClose();
              }}
            >
              Approved Pembayaran
            </MenuItem>
          )}
  
          {!isRejected && (
            <MenuItem
              onClick={() => {
                handleReject(params.row.requestId, params.row.namaLengkap, params.row.jenisIuran);
                handleMenuClose();
              }}
            >
              Tolak Pembayaran
            </MenuItem>
          )}
  
          <MenuItem
            onClick={() => {
              handleShowBuktiFotoPembayaran(params.row.buktiFoto);
              handleMenuClose();
            }}
          >
            Lihat Bukti Pembayaran
          </MenuItem>
        </Menu>
      </>
    );
  };

  const columns = [
    { field: "no", headerName: "No.", flex: 1 },
    { field: "jenisIuran", headerName: "Jenis Iuran", flex: 1 },
    { field: "biaya", headerName: "Biaya", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    { field: "keterangan", headerName: "Keterangan Admin", flex: 1 },
    { field: "requestedAt", headerName: "Tanggal Transaksi", flex: 1 },
    {
      field: "buktiFoto",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          {userDetails.role === "admin" ? (
            <AdminActionsDropdown params={params} />
          ) : (
            <CustomIconButton
              baseColor="#003158"
              hoverColor="#00213A"
              tooltipText="Lihat Bukti Pembayaran"
              icon={<VisibilityIcon sx={{ color: "WHITE" }} />}
              onClick={() => handleShowBuktiFotoPembayaran(params.row.buktiFoto)}
            />
          )}
        </div>
      ),
    },
  ];

  if (userDetails.role === "admin") {
    columns.splice(1, 0, {
      field: "namaLengkap",
      headerName: "Nama",
      flex: 1,
    });
  }

  return (
    <div>
      <DashboardTitleComponent text={"Pembayaran Iuran"} />
      <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
        <CustomButton
          onClick={handleOpenModal}
          text="Request Pembayaran Iuran"
          isFullWidth={false}
          baseColor="#003158"
          hoverColor="#00213A"
        />

        {userDetails.role === "admin" && (
          <CustomButton
            onClick={handleOpenMasterModal}
            text="Tambah Master Pembayaran"
            isFullWidth={false}
            baseColor="#003158"
            hoverColor="#00213A"
          />
        )}
      </div>

      <br></br>
      <DataGrid
        rows={rows}
        columns={columns}
        pagination
        onPageChange={(newPage) => setPage(newPage)}
        initialState={{
          pagination: {
            paginationModel: {
              page: 0,
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5, 10, 15]}
        slots={{
          toolbar: GridToolbar,
        }}
      />
      <Dialog open={open} onClose={handleCloseModal} fullWidth maxWidth="sm">
        <DialogTitle>Request Pembayaran Iuran</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>Jenis Pembayaran</Typography>
              <CustomAutocompleteField
                onFocus={handleFetchPembayaran}
                value={listPembayaran}
                rule={(value) => isBlank(value, "Jenis Pembayaran Iuran")}
                options={listPembayaranItems}
                onChange={(newValue) => {
                  setListPembayaran(newValue);
                  setSelectedFile(null);
                  setPreviewImage(null);
                }}
                placeholder="Pilih Jenis Pembayaran Iuran"
                searchBy="uuid"
                withCost={true}
              />
            </Grid>
            {listPembayaran && (
              <>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ fontWeight: "bold" }}
                  >
                    * Mohon upload bukti pembayaran sesuai dengan biaya yang
                    tertera pada jenis pembayaran
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Upload Bukti Pembayaran
                  </Typography>
                  <input
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={handleFileChange}
                    style={{
                      display: "block",
                      marginTop: "8px",
                      padding: "10px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      width: "100%",
                      maxWidth: "300px",
                    }}
                  />
                  {previewImage && (
                    <div style={{ marginTop: "10px" }}>
                      <img
                        src={previewImage}
                        alt="Bukti Pembayaran"
                        style={{
                          width: "100%",
                          maxWidth: "300px",
                          borderRadius: "5px",
                          boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                        }}
                      />
                    </div>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <CustomButton
            onClick={handleCloseModal}
            text="Cancel"
            isFullWidth={false}
            baseColor="#A92027"
            hoverColor="#8C1016"
          />
          <CustomButton
            onClick={handleRequestPembayaranIuran}
            text="Submit"
            isFullWidth={false}
            baseColor="#003158"
            hoverColor="#00213A"
          />
        </DialogActions>
      </Dialog>

      {userDetails.role === "admin" && (
        <Dialog
          open={openAddMaster}
          onClose={handleCloseMasterModal}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>Penambahan Master Iuran</DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Typography>*Jenis Iuran</Typography>
                <CustomTextField
                  value={jenisIuran}
                  onChange={(event) => setJenisIuran(event.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography>*Biaya</Typography>
                <CustomTextField
                  value={biaya}
                  onChange={(event) => setBiaya(event.target.value)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <CustomButton
              onClick={handleCloseMasterModal}
              text="Cancel"
              isFullWidth={false}
              baseColor="#A92027"
              hoverColor="#8C1016"
            />
            <CustomButton
              onClick={handleAddMasterIuran}
              text="Submit"
              isFullWidth={false}
              baseColor="#003158"
              hoverColor="#00213A"
            />
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default AjuanPembayaranMenu;
