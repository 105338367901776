import { IconButton, Tooltip } from "@mui/material";
import React from "react";

const CustomIconButton = ({ baseColor, hoverColor, onClick, icon, tooltipText }) => {
  return (
    <Tooltip title={tooltipText} arrow>
      <IconButton
        onClick={onClick}
        sx={{
          backgroundColor: baseColor,
          padding: "10px",
          fontSize: "15px",
          "&:hover": {
            backgroundColor: hoverColor,
            color: "#ffffff",
          },
          height: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {icon}
      </IconButton>
    </Tooltip>
  );
};

export default CustomIconButton;