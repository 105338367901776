import React from "react";
import LayoutNew from "./Layout";
import UserProfileMenu from "../components/menu/user/UserProfileMenu";

const UserProfile = () => {
  return (
    <LayoutNew>
      <UserProfileMenu />
    </LayoutNew>
  );
};
export default UserProfile;
