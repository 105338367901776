import DashboardTitleComponent from "../../custom/DashboardTitleComponent";
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import backCard from "../../../images/back_card.png";
import frontCard from "../../../images/front_card.png";
import axios from "axios";

const UserProfileMenu = () => {
  const [namaLengkap, setNamaLengkap] = useState("");
  const [ktp, setKtp] = useState("");
  const [gelarDepan, setGelarDepan] = useState("");
  const [gelarBelakang, setGelarBelakang] = useState("");

  useEffect(() => {
    const jwtToken = localStorage.getItem("token");
    if (jwtToken) {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_HOST}/users/details/all`,
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
              },
            }
          );
          setNamaLengkap(response.data.data.nama_lengkap);
          setKtp(response.data.data.ktp);
          setGelarDepan(response.data.data.gelar_depan);
          setGelarBelakang(response.data.data.gelar_belakang);
        } catch (error) {
          alert("Error fetching user details", error);
        }
      };
      fetchData();
    }
  }, []);

  return (
    <div>
      <DashboardTitleComponent text={"User Profile"} />
      <Grid container spacing={2}>
        <Grid item xs={6} style={{ position: "relative" }}>
          <img
            src={frontCard}
            alt="Front Card"
            style={{ width: "40%", height: "auto", marginRight: "1rem" }}
          />
          <Typography
            variant="p"
            style={{
              position: "absolute",
              top: "80%",
              left: "8%",
              color: "#fff",
              fontWeight: "bold",
              padding: "5px 10px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            {gelarDepan} {namaLengkap} {gelarBelakang}
          </Typography>
          <Typography
            variant="p"
            style={{
              position: "absolute",
              top: "85%",
              left: "8%",
              color: "#fff",
              fontWeight: "bold",
              padding: "5px 10px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            {ktp}
          </Typography>
          <img
            src={backCard}
            alt="Back Card"
            style={{ width: "40%", height: "auto" }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default UserProfileMenu;
