import { Container, useMediaQuery, Grid, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
// import logo from "../images/ikdki.png";
import { ReactComponent as Logo } from "../images/ikdki.svg";
import ImageField from "./custom/ImageField";
import CustomTextField from "./custom/CustomTextField";
import LockIcon from "@mui/icons-material/Lock";
import { doesPasswordsMatch, isBlank } from "../utils/ValidationRules";
import CustomButton from "./custom/CustomButton";
import axios from "axios";
import Swal from "sweetalert2";
import { Link, useParams, useNavigate } from "react-router-dom";

const ChangeUserPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const theme = useTheme();

  // Define media query breakpoints
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        await axios.post(
          `${process.env.REACT_APP_BACKEND_HOST}/check-password-token`,
          { token }
        );
      } catch (error) {
        Swal.fire({
          icon: "error",
          text: error.response.data.msg,
          showConfirmButton: false,
          timer: 2500,
        }).then(() => {
          navigate("/");
        });
      }
    };
    checkTokenValidity();
  }, [token, navigate]);

  const linkStyle = {
    fontWeight: "bold",
    textDecoration: "none",
    color: "#003158",
    marginLeft: "5px",
    "&:hover": {
      color: "#00213A",
    },
  };

  const changeUserPassword = async (e) => {
    e.preventDefault();
    try {
      const passwordResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_HOST}/change-user-password`,
        {
          newPassword: newPassword,
          confirmNewPassword: confirmNewPassword,
          token: token,
        }
      );
      Swal.fire({
        icon: "success",
        text: passwordResponse.data.msg,
        showConfirmButton: false,
        timer: 2500,
      }).then(() => {
        navigate("/");
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: error.response.data.msg,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#f0ebeb",
        minHeight: "100vh",
        maxHeight: "100000vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container maxWidth="md">
        <Grid
          container
          spacing={2}
          sx={{
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item md={12} xs={12} fullwidth="true">
            <Logo
              style={{
                width: isSmallScreen ? "80%" : "40%",
                height: isSmallScreen ? "80%" : "40%",
              }}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <CustomTextField
              isFullWidth={false}
              placeholder={"Password Baru"}
              icon={<LockIcon sx={{ color: "black" }} />}
              width={"90%"}
              type="password"
              value={newPassword}
              rule={(value) => isBlank(value, "Password")}
              onChange={(event) => setNewPassword(event.target.value)}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <CustomTextField
              isFullWidth={false}
              placeholder={"Konfirmasi Password Baru"}
              icon={<LockIcon sx={{ color: "black" }} />}
              width={"90%"}
              type="password"
              value={confirmNewPassword}
              rule={(value) => doesPasswordsMatch(newPassword, value)}
              onChange={(event) => setConfirmNewPassword(event.target.value)}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <CustomButton
              text="Change"
              baseColor="#003158"
              hoverColor="#00213A"
              isFullWidth={false}
              width={"90%"}
              onClick={changeUserPassword}
            />
          </Grid>
          <Link to="/" style={linkStyle}>
            kembali ke halaman login
          </Link>
        </Grid>
      </Container>
    </div>
  );
};

export default ChangeUserPassword;
