import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Container,
  CircularProgress,
} from "@mui/material";
import CustomFooter from "./custom/CustomFooter";
import logo from "../images/ikdki.png";

const RakernasWaitingRegister = () => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <div
      style={{
        backgroundColor: "#f0ebeb",
        minHeight: "100vh",
        maxHeight: "100000vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <img
        src={logo}
        alt="IKDKI Logo"
        style={{ display: "none" }}
        onLoad={() => setIsImageLoaded(true)}
      />
      {!isImageLoaded ? (
        <div style={{ textAlign: "center", paddingTop: "20%" }}>
          <CircularProgress size="8rem" thickness={2} />
        </div>
      ) : (
        <Container maxWidth="md">
          <Grid
            container
            spacing={2}
            sx={{
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item md={12} xs={12}>
              <img src={logo} alt="IKDKI Logo" width="40%" height="40%" />
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography
                variant="h5"
                sx={{
                  color: "black",
                }}
              >
                Terima Kasih Telah Mendaftar Ke Acara Rakernas IKDKI 2024 !
              </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <CustomFooter
                isFixed={true}
                text="IKDKI © 2025 by Ikatan Dosen Katholik Indonesia"
              />
            </Grid>
          </Grid>
        </Container>
      )}
    </div>
  );
};

export default RakernasWaitingRegister;
