import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Grid, Typography, Box } from "@mui/material";
import formatDateTimestamp from "../../utils/date";
import CustomButton from "../custom/CustomButton";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
// import { inverseObject } from "../../utils/format";
import { formatCamelCaseToCapital, inverseObject } from "../../utils/format";

function ConfirmationUserRequestData() {
  const navigate = useNavigate();
  const [changes, setChanges] = useState({});
  const [oldData, setOldData] = useState({});
  const { requestId } = useParams();
  const { userId } = useParams();
  const keysToExclude = [
    "uuid",
    "is_member",
    "role",
    "id_users",
    "email",
    "is_login",
    "last_login",
    "expired_card_at",
    "member_since",
    "member_ceased",
    "tanggal_meninggal",
    "is_deleted",
  ];

  const keyMapping = {
    ktp: "ktp",
    nama_lengkap: "namaLengkap",
    gelar_depan: "gelarDepan",
    gelar_belakang: "gelarBelakang",
    tempat_lahir: "tempatLahir",
    tanggal_lahir: "tanggalLahir",
    negara_asal: "negaraAsal",
    provinsi_institusi: "provinsiInstitusi",
    kota_institusi: "kotaInstitusi",
    institusi_asal: "namaInstitusi",
    prodi: "programStudi",
    jenjang: "jenjangMengajar",
    pendidikan: "pendidikanTerakhir",
    jenjang_akademik: "jenjangAkademik",
    keahlian_1: "bidangKeahlian1",
    keahlian_2: "bidangKeahlian2",
    keahlian_3: "bidangKeahlian3",
    no_hp: "nomorHandphone",
    google_scholar_id: "googleScholarId",
  };

  const acceptRequest = async () => {
    const jwtToken = localStorage.getItem("token");
    try {
      const userUpdateResponse = await axios.put(
        `${process.env.REACT_APP_BACKEND_HOST}/users`,
        { ...changes, userId },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      if (userUpdateResponse.status === 200) {
        const userRequestUpdateResponse = await axios.put(
          `${process.env.REACT_APP_BACKEND_HOST}/users/request`,
          { requestId },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );

        if (userRequestUpdateResponse.status === 201) {
          Swal.fire(
            "Success",
            "User request update successful",
            "success"
          ).then(() => {
            navigate("/edit-user");
          });
        } else {
          Swal.fire("Error", "Error updating user request data", "error");
        }
      } else {
        Swal.fire("Error", "Error updating user data", "error");
      }
    } catch (error) {
      Swal.fire("Error", "Error updating data", "error");
    }
  };

  const rejectRequest = async () => {
    const jwtToken = localStorage.getItem("token");
    try {
      const userUpdateResponse = await axios.put(
        `${process.env.REACT_APP_BACKEND_HOST}/users`,
        { ...changes, userId },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      if (userUpdateResponse.status === 200) {
        const userRequestUpdateResponse = await axios.put(
          `${process.env.REACT_APP_BACKEND_HOST}/users/request-reject`,
          { requestId },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
        if (userRequestUpdateResponse.status === 201) {
          Swal.fire({
            icon: "success",
            text: userRequestUpdateResponse.data.msg,
          }).then(() => {
            navigate("/edit-user");
          });
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: error.userRequestUpdateResponse.data.msg,
      });
    }
  };

  useEffect(() => {
    const fetchChanges = async () => {
      const jwtToken = localStorage.getItem("token");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_HOST}/users/request/changes?request_id=${requestId}`,
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );

        let responseData = response.data.data;

        if (typeof responseData === "string") {
          try {
            responseData = JSON.parse(responseData);
          } catch (parseError) {
            console.error("Error parsing response data:", parseError);
            return;
          }
        }

        if (responseData) {
          responseData.tanggalLahir = formatDateTimestamp(
            responseData.tanggalLahir
          );
          setChanges(responseData);
        } else {
          console.error("Response data is null or undefined");
        }
      } catch (error) {
        console.error("Error fetching request changes", error);
      }
    };

    if (requestId) {
      fetchChanges();
    }
  }, [requestId]);

  useEffect(() => {
    const fetchUserData = async () => {
      const jwtToken = localStorage.getItem("token");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_HOST}/users?uuid=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
        response.data.data[0].tanggal_lahir = formatDateTimestamp(
          response.data.data[0].tanggal_lahir
        );
        setOldData(response.data.data[0]);
      } catch (error) {
        console.error("Error fetching request changes", error);
      }
    };

    if (userId) {
      fetchUserData();
    }
  }, [userId]);

  return (
    <Grid container spacing={2}>
      <Grid item md={6} xs={12}>
        <Typography variant="h6">Data Lama</Typography>
        {Object.entries(oldData)
          .filter(([key]) => !keysToExclude.includes(key))
          .map(([key, value]) => (
            <Typography key={key} component="div">
              <Box component="span" fontWeight="fontWeightBold">
                {formatCamelCaseToCapital(keyMapping[key])} :
              </Box>
              {value}
            </Typography>
          ))}
      </Grid>
      <Grid item md={6} xs={12}>
        <Typography variant="h6">Data Baru</Typography>
        {Object.entries(changes).map(([key, value]) => (
          <Typography key={key} component="div">
            <Box component="span" fontWeight="fontWeightBold">
              {formatCamelCaseToCapital(key)} :
            </Box>
            <span
              style={{
                color:
                  oldData[inverseObject(keyMapping)[key]] !== changes[key]
                    ? "red"
                    : "inherit",
              }}
            >
              {value}
            </span>
          </Typography>
        ))}
      </Grid>
      <Grid item md={6} xs={12}>
        <CustomButton
          text="Terima Perubahan"
          baseColor="#003158"
          hoverColor="#00213A"
          onClick={acceptRequest}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <CustomButton
          text="Tolak Perubahan"
          baseColor="#A92027"
          hoverColor="#8C1016"
          onClick={rejectRequest}
        />
      </Grid>
    </Grid>
  );
}

export default ConfirmationUserRequestData;
