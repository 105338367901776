import { Grid } from "@mui/material";
import ImageField from "./custom/ImageField";
import CustomFooter from "./custom/CustomFooter";
import CustomButton from "./custom/CustomButton";
import logo from "../images/ikdki.png";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";

const Waiting = () => {
  const { setIsAuthenticated } = useAuth();
  const { setRole } = useAuth();
  const navigate = useNavigate();
  
  const redirectLogin = async (e) => {
    e.preventDefault();
    try {
      localStorage.removeItem("token");
      setIsAuthenticated(false);
      setRole(null);
      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#f0ebeb",
        minHeight: "100vh",
        maxHeight: "100000vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container maxWidth="md">
        <Grid
          container
          spacing={2}
          sx={{
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item md={12} xs={12} fullwidth="true">
            <ImageField src={logo} width={"40%"} height={"40%"} />
          </Grid>
          <Grid item md={12} xs={12} fullwidth="true">
            <Typography
              variant="h5"
              sx={{
                color: "black",
              }}
            >
              Your account is currently being checked and awaits verification by
              the admin. Once verified, you will be able to access the
              dashboard.
            </Typography>
          </Grid>
          <Grid item md={12} xs={12} sx={{ marginTop: "5vh" }}>
            <CustomButton
              text="Back to login page"
              baseColor="#003158"
              onClick={redirectLogin}
              hoverColor="#00213A"
              isFullWidth={false}
              width={"50%"}
            />
          </Grid>
          <Grid item md={12} xs={12} fullwidth>
            <CustomFooter
              isFixed={true}
              text="IKDKI © 2025 by Ikatan Dosen Katholik Indonesia"
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Waiting;
