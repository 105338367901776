import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  Container,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@mui/material";
import CustomTextField from "./custom/CustomTextField";
import CustomNumberField from "./custom/CustomNumberField";
import CustomButton from "./custom/CustomButton";
import CustomFooter from "./custom/CustomFooter";
import logo from "../images/ikdki.png";
import { isEmailValid, isKtpValid } from "../utils/ValidationRules";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const LandingPageRakernas = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [namaLengkap, setNamaLengkap] = useState("");
  const [email, setEmail] = useState("");
  const [nomorWhatsapp, setNomorWhatsapp] = useState("");
  const [ktp, setKtp] = useState("");
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const navigate = useNavigate();

  const registerUser = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_HOST}/rakernas-anggota`,
        {
          namaLengkap: namaLengkap,
          email: email,
          nomorWhatsapp: nomorWhatsapp,
          ktp: ktp,
        }
      );
      Swal.fire({
        icon: "success",
        text: "Registrasi Anggota Berhasil",
        showConfirmButton: false,
        timer: 1500,
      });
      setTimeout(() => {
        navigate("/success-rakernas-registration");
      }, 1500);
    } catch (err) {
      if (err.response) {
        Swal.fire({
          icon: "error",
          title: "Error",
          scrollbarPadding: false,
          text: err.response.data.msg,
        });
      }
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#f0ebeb",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <img
        src={logo}
        alt="IKDKI Logo"
        style={{ display: "none" }}
        onLoad={() => setIsImageLoaded(true)}
      />
      {!isImageLoaded ? (
        <div style={{ textAlign: "center", paddingTop: "20%" }}>
          <CircularProgress
            size="8rem"
            thickness={2}
            sx={{

            }}
          />
        </div>
      ) : (
        <Container maxWidth="lg">
          <Card
            variant="outlined"
            sx={{
              padding: "15px",
              margin: "10px",
              marginTop: "50px",
              borderRadius: 5,
            }}
          >
            <Box
              sx={{
                margin: "25px",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  textTransform: "uppercase",
                  marginBottom: "20px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item md={2} xs={12} sx={{ textAlign: "center" }}>
                    <img src={logo} alt="IKDKI Logo" width="70%" height="70%" />
                  </Grid>
                  <Grid
                    item
                    md={10}
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    Registrasi Rakernas IKDKI 2024
                  </Grid>
                </Grid>
              </Typography>
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <Typography>Nama Lengkap</Typography>
                  <CustomTextField
                    value={namaLengkap}
                    onChange={(event) => setNamaLengkap(event.target.value)}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Typography>Nomor Whatsapp</Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "#A92027",
                    }}
                  >
                    *Note : Pastikan nomor Whatsapp yang dimasukan merupakan
                    nomor whatsapp aktif
                  </Typography>
                  <CustomNumberField
                    value={nomorWhatsapp}
                    onChange={(event) => setNomorWhatsapp(event.target.value)}
                    maxInputLength={14}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography>Email</Typography>
                  <CustomTextField
                    type="email"
                    value={email}
                    rule={isEmailValid}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography>KTP</Typography>
                  <CustomNumberField
                    value={ktp}
                    rule={isKtpValid}
                    onChange={(event) => setKtp(event.target.value)}
                    maxInputLength={16}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                  sx={{
                    marginTop: "20px",
                  }}
                >
                  <CustomButton
                    text="Daftar"
                    baseColor="#003158"
                    hoverColor="#00213A"
                    onClick={registerUser}
                  />
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Container>
      )}
      <Grid item md={12} xs={12} fullwidth="true">
        <CustomFooter
          isFixed={true}
          text="IKDKI © 2025 by Ikatan Dosen Katholik Indonesia"
        />
      </Grid>
    </div>
  );
};

export default LandingPageRakernas;
