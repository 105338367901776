import React from "react";
import LayoutNew from "./Layout";
import AjuanPembayaranMenu from "../components/menu/user/AjuanPembayaranMenu";

const AjuanPembayaran = () => {
  return (
    <LayoutNew>
      <AjuanPembayaranMenu />
    </LayoutNew>
  );
};

export default AjuanPembayaran;
