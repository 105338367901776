import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import ImageField from "./custom/ImageField";
import CustomTextField from "./custom/CustomTextField";
import CustomButton from "./custom/CustomButton";
import CustomFooter from "./custom/CustomFooter";
// import logo from "../images/ikdki.png";
import { ReactComponent as Logo } from "../images/ikdki.svg";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { isBlank, isEmailValid } from "../utils/ValidationRules";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";
import axios from "axios";
import Swal from "sweetalert2";
import { useAuth } from "./AuthProvider";

const Login = () => {
  const {
    isAuthenticated,
    setIsAuthenticated,
    isMember,
    setIsMember,
    role,
    setRole,
  } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      if (role !== "admin") {
        navigate("/update");
      } else {
        navigate("/dashboard");
      }
    }
  }, [isAuthenticated, navigate, role]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginExecuted, setLoginExecuted] = useState(false);

  const linkStyle = {
    fontWeight: "bold",
    textDecoration: "none",
    color: "#003158",
    marginLeft: "5px",
    "&:hover": {
      color: "#00213A",
    },
  };

  const theme = useTheme();

  // Define media query breakpoints
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    if (loginExecuted && isAuthenticated) {
      if (isMember) {
        var redirectPath = "";
        var successMessage = "";
        if (role !== "admin") {
          redirectPath = "/update";
          successMessage = "Welcome";
        } else {
          redirectPath = "/dashboard";
          successMessage = "Welcome Admin";
        }
        Swal.fire({
          icon: "success",
          title: successMessage,
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          navigate(redirectPath);
        });
      } else {
        navigate("/waiting");
      }
    }
  }, [loginExecuted, isAuthenticated, navigate, isMember, role]);

  const loginUser = async (e) => {
    // e.preventDefault();
    try {
      const loginResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_HOST}/login`,
        {
          email,
          password,
        }
      );
      if (loginResponse.data.token) {
        localStorage.setItem("token", loginResponse.data.token);
        const userDetailsResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_HOST}/users/details`,
          {
            headers: {
              Authorization: `Bearer ${loginResponse.data.token}`,
            },
          }
        );
        setIsAuthenticated(true);
        setIsMember(userDetailsResponse.data.is_member);
        setRole(userDetailsResponse.data.role);
        setLoginExecuted(true);
      }
    } catch (err) {
      if (err.response) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data.msg,
        });
      }
    }
  };
  return (
    <div
      style={{
        backgroundColor: "#f0ebeb",
        minHeight: "100vh",
        maxHeight: "100000vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container maxWidth="md">
        <Grid
          container
          fullWidth
          sx={{
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item
            md={12}
            xs={12}
            fullwidth="true"
            sx={{
              marginTop: "5vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Logo
              style={{
                width: isSmallScreen ? "80%" : "40%",
                height: isSmallScreen ? "80%" : "40%",
              }}
            />
          </Grid>
          <Grid container spacing={2} sx={{ textAlign: "center" }}>
            <Grid item md={12} xs={12}>
              <CustomTextField
                isFullWidth={false}
                placeholder={"email@example.com"}
                icon={<AccountCircleIcon sx={{ color: "black" }} />}
                width={"90%"}
                type="email"
                value={email}
                rule={isEmailValid}
                onChange={(event) => setEmail(event.target.value)}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <CustomTextField
                isFullWidth={false}
                placeholder={"********"}
                icon={<LockIcon sx={{ color: "black" }} />}
                width={"90%"}
                onSubmit={loginUser}
                type="password"
                value={password}
                rule={(value) => isBlank(value, "Password")}
                onChange={(event) => setPassword(event.target.value)}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <CustomButton
                text="Confirm"
                baseColor="#003158"
                hoverColor="#00213A"
                isFullWidth={false}
                width={"90%"}
                onClick={loginUser}
              />
            </Grid>
            <Grid item md={12} xs={12} fullwidth="true">
              <Typography sx={{ color: "black" }}>
                Belum punya akun ?
                <Link to="/register" style={linkStyle}>
                  Daftar Disini
                </Link>
              </Typography>
            </Grid>
            <Grid item md={12} xs={12} fullwidth="true">
              <Typography sx={{ color: "black" }}>
                <Link to="/reset-password" style={linkStyle}>
                  Lupa Password Klik Disini
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12} fullwidth="true">
            <CustomFooter
              isFullWidth={true}
              isFixed={true}
              text="IKDKI © 2025 by Ikatan Dosen Katholik Indonesia"
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Login;
